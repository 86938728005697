<template>
  <div class="row">
    <div class="col-12">
      <section class="card shadow w3-white mb-4">
        <div class="card-body m-0 bg-transparent">
          <div class="row mb-2">
            <div class="col-sm-5">
              <h4 class="bold">Uninvested Funds</h4>
            </div>
            <div class="col-sm-7">
              <div class="w3-right">
                <DateRangeFilter @gettransactions="getTransactions" />
              </div>
            </div>
          </div>
          <div v-if="fetching">
            <img class="mr-3" src="base/img/loading.gif" width="40" />
            Loading transactions
          </div>
          <div v-else>
            <TransactionsTable
              tableid="uninvested"
              :headers="TableHeader"
              :rows="Transactions"
              source="uninvested"
            />
          </div>
        </div>
      </section>
    </div>
  </div>
</template>
<script>
import { useStore } from "vuex";
import { computed, onMounted, onBeforeMount, ref } from "vue";
import TransactionsTable from "@/components/TransactionsTable";
import DateRangeFilter from "@/components/DateRangeFilter";
export default {
  components: { TransactionsTable, DateRangeFilter },
  setup() {
    const store = useStore();
    const fetching = computed(() => store.state.fetching.UninvestedFund);
    const productType = computed(() => store.state.InvestmentCodes);
    const fundKey = ref("");

    const activeRedemption = ref(productType.value[0]);
    const Period = ref({
      StartDate: "",
      EndDate: "",
    });
    const Transactions = computed(() => store.state.UninvestedFund);
    if (!Transactions.value?.length) {
      store.dispatch("UninvestedFund", {
        StartDate: "",
        EndDate: "",
      });
    }

    const TransactionsList = computed(() => {
      const Trans = {};
      for (let trans in Transactions.value) {
        let FundCode = Transactions.value[trans].FundCode;

        if (typeof Trans[FundCode] === "undefined") {
          Trans[FundCode] = [];
        }
        Trans[FundCode].push(Transactions.value[trans]);
      }
      return Trans;
    });

    const TableHeader = ref([
      { text: "Payment Date", value: "PaymentDate" },
      { text: "Amount Paid", value: "AmountPaid" },
      { text: "Reference", value: "Reference" },
      { text: "Fund Name", value: "FundName" },
      { text: "Fund Code", value: "FundCode" },
    ]);

    const getTransactions = (period) => {
      store.dispatch("UninvestedFund", {
        StartDate: period.StartDate,
        EndDate: period.EndDate,
      });
    };
    onBeforeMount(() => {
      store.dispatch("resetIdleTime");
    });
    onMounted(() => {
      fundKey.value = productType.value[0];
    });
    return {
      TableHeader,
      Transactions,
      fetching,
      productType,
      getTransactions,
      activeRedemption,
      TransactionsList,
      Period,
      fundKey,
    };
  },
};
</script>