<template>
  <section role="main" class="content-body">
    <header class="page-header">
      <h2>{{ title }}</h2>
    </header>
    <div class="p-4">
      <div class="row">
        <div class="col-md-10 w3-border-right">
          <Transactions />
        </div>
        <div class="col-md-2"></div>
      </div>
    </div>
  </section>
</template> 
<script>
import Transactions from "@/components/UninvestedTransactions";
import { ref, onBeforeMount } from "vue";
import { useStore } from "vuex";
export default {
  components: {
    Transactions,
  },

  setup() {
    const title = ref("Uninvested Funds");
    const store = useStore();
    onBeforeMount(() => {
      store.dispatch("resetIdleTime");
    });
    return {
      title,
    };
  },
};
</script>
